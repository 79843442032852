/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
<template>
  <b-card v-if="domain">
    <b-row>
      <b-col cols="12">
        <b-button
          variant="primary"
          size="md"
          class="btn-icon float-right mb-1 mr-1"
          @click="editItem"
        >
          <feather-icon icon="Edit2Icon" />
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="4">
        <p>Name</p>
      </b-col>
      <b-col cols="4">
        <p>{{ domain.name }}</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <p>Email</p>
      </b-col>
      <b-col cols="4">
        <p>{{ domain.email }}</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <p>Url</p>
      </b-col>
      <b-col cols="4">
        <p>{{ domain.url }}</p>
      </b-col>
    </b-row>

    <b-row>
      <h4 class="w-100 ml-1 my-2">
        SMTP
      </h4>
      <b-col cols="6">
        <b-row>
          <b-col cols="6">
            <p>SMTP Username</p>
          </b-col>
          <b-col cols="6">
            <p>{{ domain.smtp.username }}</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row>
          <b-col cols="6">
            <p>SMTP Password</p>
          </b-col>
          <b-col cols="6">
            <p>{{ domain.smtp.password }}</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row>
          <b-col cols="6">
            <p>SMTP Port</p>
          </b-col>
          <b-col cols="6">
            <p>{{ domain.smtp.port }}</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row>
          <b-col cols="6">
            <p>SMTP Secure</p>
          </b-col>
          <b-col cols="6">
            <p>{{ domain.smtp.secure ? 'Yes' : 'No' }}</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row>
          <b-col cols="6">
            <p>SMTP NoReply</p>
          </b-col>
          <b-col cols="6">
            <p>{{ domain.smtp.noReply }}</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <h4 class="w-100 ml-1 my-2">
        IMAP
      </h4>
      <b-col cols="6">
        <b-row>
          <b-col cols="6">
            <p>IMAP Username</p>
          </b-col>
          <b-col cols="6">
            <p>{{ domain.imap.username }}</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row>
          <b-col cols="6">
            <p>IMAP Password</p>
          </b-col>
          <b-col cols="6">
            <p>{{ domain.imap.password }}</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row>
          <b-col cols="6">
            <p>IMAP Port</p>
          </b-col>
          <b-col cols="6">
            <p>{{ domain.imap.port }}</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row>
          <b-col cols="6">
            <p>IMAP Host</p>
          </b-col>
          <b-col cols="6">
            <p>{{ domain.imap.host }}</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row>
          <b-col cols="6">
            <p>IMAP TLS</p>
          </b-col>
          <b-col cols="6">
            <p>{{ domain.imap.tls ? 'Yes' : 'No' }}</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
/* eslint-disable */

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BFormSelect
} from 'bootstrap-vue'

export default {
  name: 'EditDomain',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BFormSelect
  },
  props: [
    'domainId',
  ],
  data() {
    return {
      domain: '',
      loading: false,
      booleanOptions: [
        {
          value: true,
          text: "Yes",
        },
        {
          value: false,
          text: "No",
        }
      ]
    }
  },
  beforeMount() {
    this.loadDomain()
  },
  methods: {
    async loadDomain() {
      const self = this

      self.loading = true
      await this.$store.dispatch('app/fetchDomain', self.domainId)
        .then(() => {
          self.domain = self.$store.state.app.domain
        })
        .catch(() => {
        })
        .finally(() => {
          self.loading = false
        })
    },
    editItem() {
      this.$router.push({ name: 'editDomain', params: { domainId: this.domainId } })
    },
  },
}
</script>
